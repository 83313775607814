export enum DOMAIN_NUMBER {
    LOCALHOST = 2,
    TIKFAMECONTENTBOOTS = 3,
    TIKFAME = 4,
    TIKFAME_STAGING = 5,
}

export const DOMAINS: Record<DOMAIN_NUMBER, string> = {
    [DOMAIN_NUMBER.LOCALHOST]: 'http://localhost:3001/dashboard',
    [DOMAIN_NUMBER.TIKFAMECONTENTBOOTS]: '/',
    [DOMAIN_NUMBER.TIKFAME_STAGING]:
        'https://www.app-staging.tikfame.co/dashboard',
    [DOMAIN_NUMBER.TIKFAME]: 'https://www.app.tikfame.co/dashboard',
};
